import React from 'react'
import { useSelector, RootStateOrAny } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import PrivateRoute from './common/PrivateRoute'
import Home from './component/home/Home'
import Login from './component/login/Login'
import LoginEts from './component/login/LoginEts'
import NotFound from './common/NotFound'
import DepositRoutes from './component/deposit/DepositRoutes'
import { ToastContainer } from 'react-toastify'
import RequestRoutes from './component/requests/RequestRoutes'
import PaymentRoutes from './component/payment/PaymentRoutes'
import InscriptionRoutes from './component/inscription/InscriptionRoutes'
import Payment from './component/payment/Payment'
import {
  PAYMENT_URL_DIVISION,
  PAYMENT_URL_NOTIFICATION,
  PAYMENT_URL_TRANSFORMATION_TN_TA
} from './constants/PaymentConstants'
import {
  ACCESS_DEMAND_ITEM_ADD_AGENT, ACCESS_DEMAND_ITEM_ATTACH_PM,
  ACCESS_DEMAND_ITEM_CREDENTIALS,
  ACCESS_DEMAND_ITEM_DEACTIVATE_AGENT,
  ACCESS_DEMAND_ITEM_EDIT_AGENT
} from './constants/ETSAccountConstants'
import ETSAccount from './component/ETSAccountRequest/ETSAccount'
import ETSAccountCredentials from './component/ETSAccountRequest/ETSAccountRequestCredentials'
import SearchList from './component/search/SearchList'
import ETSAccountPM from './component/ETSAccountRequest/ETSAccountPM'
import Help from './component/help/Help'
import ProrogationRoutes from './component/prorogation/ProrogationRoutes'
import AppealRoutes from './component/appeal/AppealRoutes'
import OfficialDocumentRoutes from './component/officialDocument/OfficialDocumentRoutes'

const App = () => {
  const currentUser = useSelector((state : RootStateOrAny) => state.user.user)
  const currentTokenETS = useSelector((state : RootStateOrAny) => state.user.tokenETS)
  const isAuthenticated = !!currentUser

  return (
    <div className='global'>
      <Switch>
        <PrivateRoute
          path='/liste-demandes'
          component={RequestRoutes}
          authed={isAuthenticated}
        />
        <PrivateRoute
          path='/paiement'
          component={PaymentRoutes}
          authed={isAuthenticated}
        />
        <PrivateRoute
          path='/depots'
          component={DepositRoutes}
          authed={isAuthenticated}
        />
        <PrivateRoute
          path='/inscriptions'
          component={InscriptionRoutes}
          authed={isAuthenticated}
        />
        <PrivateRoute
          path='/prorogations'
          component={ProrogationRoutes}
          authed={isAuthenticated}
        />
        <PrivateRoute
          path='/releves-de-decheance'
          component={AppealRoutes}
          authed={isAuthenticated}
        />
        <PrivateRoute
          path='/documents-officiels'
          component={OfficialDocumentRoutes}
          authed={isAuthenticated}
        />
        <PrivateRoute
          path={`/${PAYMENT_URL_NOTIFICATION}/:id/paiement/:state`}
          component={(props) => <Payment id={props.match.params.id} state={props.match.params.state} matchUrl={PAYMENT_URL_NOTIFICATION} />}
          authed={isAuthenticated}
        />
        <PrivateRoute
          path={`/${PAYMENT_URL_DIVISION}/:id/paiement/:state`}
          component={(props) => <Payment id={props.match.params.id} state={props.match.params.state} matchUrl={PAYMENT_URL_DIVISION} />}
          authed={isAuthenticated}
        />
        <PrivateRoute
          path={`/${PAYMENT_URL_TRANSFORMATION_TN_TA}/:id/paiement/:state`}
          component={(props) => <Payment id={props.match.params.id} state={props.match.params.state} matchUrl={PAYMENT_URL_TRANSFORMATION_TN_TA} />}
          authed={isAuthenticated}
        />
        <PrivateRoute
          path='/recherche'
          component={SearchList}
          authed={isAuthenticated}
        />
        <PrivateRoute
          path='/aide'
          component={Help}
          authed={isAuthenticated}
        />
        <PrivateRoute
          path='/home'
          component={Home}
          authed={isAuthenticated}
        />
        <PrivateRoute
          path='/code-acces'
          component={() => <ETSAccountCredentials ETSAccountRequestNavItem={ACCESS_DEMAND_ITEM_CREDENTIALS} />}
          authed={isAuthenticated}
        />
        <PrivateRoute
          path='/attachement-pm'
          component={() => <ETSAccountPM ETSAccountRequestNavItem={ACCESS_DEMAND_ITEM_ATTACH_PM} />}
          authed={isAuthenticated}
        />
        <PrivateRoute
          path='/rattachement-nouveau-compte'
          component={() => <ETSAccount ETSAccountRequestNavItem={ACCESS_DEMAND_ITEM_ADD_AGENT} />}
          authed={isAuthenticated}
        />
        <PrivateRoute
          path='/rattachement-modification-compte'
          component={() => <ETSAccount ETSAccountRequestNavItem={ACCESS_DEMAND_ITEM_EDIT_AGENT} />}
          authed={isAuthenticated}
        />
        <PrivateRoute
          path='/detachement-compte'
          component={() => <ETSAccount ETSAccountRequestNavItem={ACCESS_DEMAND_ITEM_DEACTIVATE_AGENT} />}
          authed={isAuthenticated}
        />
        <Route
          path='/ets/:token'
          render={(props) => (isAuthenticated && props.match.params.token === currentTokenETS
            ? (
              <Redirect to={{
                pathname: '/home',
                state: { from: props.location }
              }}
              />
            ) : (
              <LoginEts token={props.match.params.token} />
            )
          )}
        />
        <Route
          path='/login'
          render={(props: any) => (isAuthenticated
            ? (
              <Redirect to={{
                pathname: '/home',
                state: { from: props.location }
              }}
              />
            ) : (
              <Login />
            )
          )}
        />
        <PrivateRoute
          path='/'
          component={Home}
          authed={isAuthenticated}
        />
        <Route component={NotFound} />
      </Switch>
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
      />
    </div>
  )
}

export default App
