const Message = {
  // Upload images
  'file-too-large': 'Le poids du fichier est supérieur à 5Mo',
  'file-invalid-type': 'Le format du fichier n\'est pas autorisé',
  drop_files: 'Glissez-déposez vos reproductions ou cliquez pour parcourir (.jpeg, .jpg ou .png)',
  overview_deposit_regularize_add_reproductions: 'Ajouter des reproductions à régulariser',
  request_reproduction_completion_new_repro: 'Glissez-déposez ou cliquez pour proposer une nouvelle reproduction',
  official_document_procedure_bloc_generate_do_download_file: 'Glisser-déposer ou cliquer pour parcourir',

  // Common
  common_validate: 'Valider',
  placeholder_select: 'Sélectionner ...',
  button_return: 'Retour',
  autocomplete_no_resultat: 'Aucun résultat disponible',
  to_determined: 'À déterminer',
  compliant: 'Conforme',
  not_compliant: 'Non conforme',
  common_start_form: 'Débuter le formulaire',
  required_field: 'Champ obligatoire',
  validation_start_date: 'La date de début est supérieure à la date de fin',
  validation_end_date: 'La date de fin est inférieure à la date de début',
  invalid_number: 'Numéro invalide',
  records_inscription_origin_fr: 'D&M Français',
  records_inscription_origin_int_fr: 'D&M International',

  // Formulaire : Intervenant
  intervenant_form_title: 'Identification des intervenants',
  intervenant_copier_donnees: 'Copier les données d\'un autre intervenant',
  intervenant_auto_depositor: 'Je suis le déposant',
  intervenant_auto_depositorProrogation: 'Je suis le déclarant',
  intervenant_auto_depositorApplicant: 'Je suis le demandeur',
  intervenant_onglet_depositor: 'Déposant',
  intervenant_onglet_depositorProrogation: 'Déclarant',
  intervenant_onglet_depositorApplicant: 'Demandeur',
  intervenant_onglet_depositor_subtitle: 'Il peut y avoir un ou plusieurs déposants. Il s\'agit du ou des propriétaires des dessins et modèles. Pour en savoir plus, <a>consulter l\'aide en ligne.</a>',
  intervenant_onglet_depositorProrogation_subtitle: 'Il peut y avoir un ou plusieurs déclarants. Pour en savoir plus, <a>consulter l\'aide en ligne.</a>',
  intervenant_onglet_depositorApplicant_subtitle: 'Il y a un demandeur. Pour en savoir plus, <a>consulter l\'aide en ligne.</a>',
  intervenant_onglet_payer: 'Payeur',
  intervenant_onglet_coDepositors: 'Co-déposants',
  intervenant_onglet_applicants: 'Demandeurs',
  intervenant_onglet_beneficiaries: 'Bénéficiaire',
  intervenant_onglet_coDepositorsProrogation: 'Co-déclarants',
  intervenant_beneficiary: 'Bénéficiaire',
  intervenant_onglet_otherApplicants: 'Autre(s) partie(s) à l\'acte',
  intervenant_onglet_destinataire: 'Destinataire',
  intervenant_destinataire_subtitle: 'Il s\'agit de la personne à qui l\'INPI va adresser la correspondance de procédure. Pour en savoir plus, <a>consulter l\'aide en ligne.</a>',
  intervenant_onglet_mandataire: 'Mandataire',
  intervenant_onglet_titulaires: 'Titulaires',
  intervenant_onglet_mandataire_subtitle: 'Il s\'agit de la personne chargée de représenter le ou les déposants devant l\'INPI. Il peut être obligatoire. Pour savoir dans quels cas et qui peut être manadataire, <a>consulter l\'aide en ligne.</a>',
  intervenant_onglet_createur: 'Créateurs',
  intervenant_onglet_signatory: 'Signataire',
  intervenant_creators_subtitle: 'Le(s) créateur(s) peuvent s\'identifier par leurs noms et prénoms. Pour en savoir plus, <a>consulter l\'aide en ligne.</a>',
  intervenant_signatory_subtitle: 'Le signataire est en principe le titulaire du compte e-procedure. Il doit avoir la qualité requise pour effectuer le dépôt. Pour en savoir plus, <a>consulter l\'aide en ligne.</a>',
  intervenant_auto_coDepositors: 'Je suis le co-déposant',
  intervenant_auto_applicants: 'Je suis le demandeur',
  intervenant_auto_otherApplicants: 'Je suis autre partie à l\'acte',
  intervenant_auto_coDepositorsProrogation: 'Je suis le co-déclarant',
  intervenant_add_co_deposant: 'Ajouter un co-déposant',
  intervenant_add_applicant: 'Ajouter un demandeur',
  intervenant_add_other: 'Ajouter une autre partie à l\'acte',
  intervenant_save_coDepositors: 'Enregistrer le co-déposant',
  intervenant_save_coDepositorsProrogation: 'Enregistrer le co-déclarant',
  intervenant_save_applicants: 'Enregistrer le demandeur',
  intervenant_save_otherApplicants: 'Enregistrer la partie',
  intervenant_save_beneficiaries: 'Enregistrer le bénéficiaire',
  intervenant_coDepositors_edit: 'Co-déposant',
  intervenant_beneficiaries_edit: 'Bénéficiaires',
  intervenant_coDepositorsProrogation_edit: 'Co-déclarant',
  intervenant_applicants_edit: 'Demandeur(s)',
  intervenant_otherApplicants_edit: 'Autre(s) partie(s) à l\'acte',
  intervenant_reinitialiser: 'Réinitialiser les champs',
  intervenant_personne_morale_publique: 'Personne morale de droit public',
  intervenant_personne_physique_formation: 'J\'agis au nom et pour le compte d\'une entité en cours de formation',
  intervenant_destinataire_titre: 'Destinataire des correspondances',
  intervenant_auto_destinataire: 'Je suis le destinataire des correspondances',
  intervenant_auto_mandataire: 'Je suis le mandataire',
  intervenant_add_mantataire: 'Ajouter un mandataire',
  intervenant_remove_mandataire: 'Supprimer le mandataire',
  intervenant_mandataire_document_info: 'Télécharger un exemple de pouvoir',
  intervenant_show_power_file: 'Voir le document de pouvoir',
  intervenant_show_proof_file: 'Voir le document justificatif',
  intervenant_correction_deleted: 'Intervenant supprimé',
  intervenant_correction_undefined: 'Intervenant non défini',
  intervenant_error_denomination_not_empty: 'La raison sociale ne doit pas être vide pour rechercher une entreprise',
  intervenant_error_bad_siren: 'Le siren ne doit pas être vide et doit contenir 9 chiffres',
  intervenant_error_api_error: 'Service momentanément indisponible',
  intervenant_select_company: 'Sélectionnez une entreprise',
  intervenant_table_label_denomination: 'Dénomination',
  intervenant_table_label_siren: 'Siren',
  intervenant_applicants: 'Demandeur(s)',
  intervenant_other_applicants: 'Autre(s) partie(s) à l\'acte',
  intervenant_nationalite: 'Nationalité',

  intervenant_warning_applicant_needed: 'Au moins un demandeur est nécessaire',
  intervenant_warning_other_applicant_needed: 'Au moins une autre partie à l\'acte est nécessaire',
  intervenant_warning_agent_is_recipient: 'Attention, en cochant cette case vous devenez l\'unique destinataire des correspondances adressées par l\'INPI sur ce(s) titre(s), y compris en dehors des procédures',
  intervenant_warning_is_recipient: 'Destinataire des correspondances liées au titre',

  personne_type_physique: 'Personne physique',
  personne_type_morale: 'Personne morale',

  civilite_monsieur: 'Monsieur',
  civilite_madame: 'Madame',
  // Formulaire : Intervenant
  intervenant_siren_modal: 'Renseigner un numéro de siren',
  intervenant_siren_close: 'Annuler',

  // Champs de formulaires
  field_personne_type_label: 'Personne',
  field_civilite_label: 'Civilité',
  field_nom_label: 'Nom',
  field_prenom_label: 'Prénom',
  field_telephone_label: 'Téléphone',
  field_email_label: 'Email',
  field_raison_sociale_label: 'Raison sociale',
  field_raison_sociale_label_long_text_needed: 'La dénomination ne peut dépasser 120 caractères, veuillez renseigner la dénomination complète dans le champ ci-dessous. La dénomination courte sera utilisée pour les communications avec l\'INPI.',
  field_raison_sociale_formation_label: 'Raison sociale de l\'entité en cours de formation',
  field_forme_juridique_label: 'Forme juridique',
  field_forme_juridique_formation_label: 'Nature de l\'entité en cours de formation',
  field_numero_siren_label: 'N° SIREN',
  field_adresse_label: 'Adresse',
  field_adresse_batiment_label: 'Entrée / Bâtiment',
  field_adresse_libelle_label: 'Libellé de l\'adresse',
  field_adresse_mention_label: 'Mention spéciale',
  field_adresse_code_postal_label: 'Code postal',
  field_adresse_ville_label: 'Ville',
  field_adresse_pays_label: 'Pays',
  field_company_label: 'Cabinet ou société',
  field_manageable_qualities_label: 'J\'agis en qualité de',
  field_inscription_number: 'Numéro CPI',
  field_power_documents_label: 'Document de pouvoir (obligatoire en cas de pluralité de déposants)',
  field_agent_is_recipient: 'Si vous êtes également le destinataire des correspondances afférentes à l\'enregistrement ou à la délivrance et au maintien en vigueur du (des) titres(s) concerné(s), cochez cette case.',

  placeholder_adresse_search: 'Rechercher une adresse ...',
  field_creators_placeholder: 'Renseigner les créateurs ...',

  // search
  search_header_label_numnat: 'NumNat',
  search_header_label_deposit_type: 'Type',
  search_header_label_holders: 'Titulaire(s)',
  search_header_label_status: 'Statut',
  search_header_label_last_transaction: 'Date de la dernière transaction',
  search_header_label_deposit_date: 'Date de dépôt',
  search_header_label_examiner: 'Examinateur',

  // deposit type
  select_depot_type_classique_title: 'Dépôt classique',
  select_depot_type_classique_resume: 'Le dépôt classique est la procédure standard ouverte à tous et qui est soumise au respect de certaines règles. Elle comprend la publication immédiate de l\'ensemble des reproductions jointes au dépôt au Bulletin Officiel de la Propriété Industrielle.',
  select_depot_type_ajourne_title: 'Dépôt ajourné',
  select_depot_type_ajourne_resume: 'L\'ajournement de la publication vous permet de garder au secret votre dépôt tout en prenant le temps de réfléchir à l\'opportunité de rendre publiques vos créations. Dans ce cas, la publication aura lieu 3 ans après le dépôt. Toutefois, vous pouvez la demander à tout moment avant la fin de ce délai. Cette demande de publication portera sur l\'ensemble des reproductions jointes au dépôt.',
  select_depot_type_simplifie_title: 'Dépôt simplifié',
  select_depot_type_simplifie_resume: 'Le dépôt simplifié est une procédure allégée sur le plan formel, qui permet de payer certaines redevances ultérieurement et qui se caractérise par une publication effectuée uniquement sur demande du déposant. Cette procédure est réservée aux seuls créateurs et entreprises renouvelant fréquemment la forme ou le décor de leurs produits. Dans ce cas vous disposez de 30 mois au maximum après le dépôt pour demander la publication. Cette demande peut porter sur l\'ensemble ou sur une partie des reproductions jointes au dépôt.',

  // inscription type
  select_type_inscription_correction_title: 'Rectification',
  select_type_inscription_ownership_title: 'Actes',
  select_type_inscription_disclaimer_title: 'Renonciation',
  select_type_inscription_office_title: 'Office',
  select_process_inscription_tn: 'Traitement normal',
  select_process_inscription_ta: 'Traitement accéléré',
  title_validation_modal_select_title_select_label: ' titres correspondent à la recherche, veuillez choisir un titre',

  // transaction selection
  transaction_selection_modal_select_transaction_subtitle: ' transactions correspondent à la recherche, veuillez en choisir une',

  // Notification
  request_notification_no_response_expected: 'Sans réponse attendue',
  request_notification_agreement_notification: 'Notification d\'office',
  request_notification_response_expected: 'Réponse attendue',

  error_email_format: 'L\'email renseigné n\'est pas au bon format',
  error_numero_siren_format: 'Numéro de siren invalide',
  error_phone_format: 'Numéro de téléphone invalide',
  error_code_postal_format: 'Code postal invalide',

  // Type de procédure
  procedure_type_deposit: 'Dépôt',
  procedure_type_prorogation: 'Prorogation',
  procedure_type_inscription: 'Inscription',
  procedure_type_appeal: 'Relevé de déchéance',
  procedure_type_official_documents: 'Documents officiels',

  // Tableau
  request_10_rows: 'Afficher 10 résultats',
  request_25_rows: 'Afficher 25 résultats',
  request_50_rows: 'Afficher 50 résultats',
  request_all_result: 'Tout afficher',
  request_no_result: 'Aucun résultat',

  // Locarno PopUp
  placeholder_locarno_search: 'Rechercher une catégorie ...',
  placeholder_locarno_search_popup: 'Rechercher dans la classification de Locarno',
  locarno_empty_products: 'Aucun produit n\'a été trouvé dans cette sous-classe',
  lcoarno_not_result_found: 'Aucun résultat, veuillez modifier votre recherche',

  // Overview d'un compte SSO
  sso_account_is_recipient: 'Cette personne a aussi indiqué être le destinataire des correspondances afférentes à l’enregistrement ou au maintien en vigueur du titre concerné',
  sso_account_link_record: 'Accéder au titre lié à la demande',
  sso_account_quality: 'Qualité : ',
  sso_account_inscription_number: 'N° CPI : ',
  sso_account_undefined: 'Compte ETS non défini',
  sso_account_state_active: 'Actif',
  sso_account_state_new: 'Nouveau',
  sso_account_state_edit: 'Modification',
  sso_account_state_inactivate: 'Inactif',

  // Overview d'un intervenant
  contributor_pp_person_formation_start: ' agissant au nom et pour le compte de la société ',
  contributor_pp_person_formation_nature: ', ayant pour nature : ',
  contributor_pp_person_formation_end: ', en cours de formation',
  contributor_pm_person_formation_start: 'Société ',

  // Intervenant Ajouter dans bénéficiaire
  contributor_add_beneficiary: ' Ajouter comme Bénéficiaire',

  // État d'une reproduction
  request_state_withdrawn: 'Retirée',
  request_state_rejected: 'Rejetée',
  request_state_renounced: 'Renoncé',
  request_state_canceled: 'Annulé',
  request_state_not_acceptable: 'Irrecevable',
  request_state_publication_requested: 'Publication demandée',
  request_state_registered_5_years: 'Enregistrée 5 ans',
  request_state_BOPI_insertion_requested: 'Insertion BOPI demandée',
  request_state_extended_10_years: 'Prorogée 10 ans',
  request_state_extended_15_years: 'Prorogée 15 ans',
  request_state_extended_20_years: 'Prorogée 20 ans',
  request_state_extended_25_years: 'Prorogée 25 ans',
  request_state_fallen: 'Déchue',
  request_state_end_of_right: 'Fin de droit',

  request_actions_error: 'Action impossible',

  // Scope d'une prorogation
  records_prorogation_isScopePartial_no: 'L\'ensemble du dépôt',
  records_prorogation_isScopePartial_yes: 'Une partie du dépôt'
}

export default Message
