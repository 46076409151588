import React, { FC, useEffect, useState } from 'react'
import { FormattedMessage, IntlProvider, WrappedComponentProps } from 'react-intl'
import { Button } from 'react-bootstrap'
import {
  AddressApiResult,
  AddressFields,
  AutocompleteResult, Country, CPI,
  DepositIntervenant,
  EventType,
  FieldStatus,
  FileBrowserField,
  isFilled, ManageableQuality,
  ManageableQualitySelectField,
  PersonneContactFields,
  PersonnePhysiqueFields,
  SelectButton,
  TextField,
  DepositDocument
} from '..'
import Message from '../constants/Message'
import SubtitleIntervenant from './intervenant/SubtitleIntervenant'

import CheckboxField from '../form/fields/CheckboxField'

interface AgentFormProps extends WrappedComponentProps {
  foDisplay?: boolean | null,
  boDisplay? : boolean | null
  checkIsRecipientNeeded? : boolean | null
  idCurrentUser?: number | null,
  user?: any | null,
  depositAgent: DepositIntervenant,
  fieldStatus: FieldStatus,
  intervenants?: AutocompleteResult<DepositIntervenant>[] | null,
  handleSelectIntervenant?: (intervenant: DepositIntervenant) => void | null,
  handleAgentForm?: (agent: DepositIntervenant) => void | null,
  addAgentDocument?: (event: EventType, intervenant: DepositIntervenant) => void | null,
  deleteAgentDocument?: (intervenant: DepositIntervenant) => void | null,
  handleSelfAgent?: () => void | null,
  handleResetAgent?: () => void | null,
  handleChange?: (event: EventType) => void,
  agentDocuments?: DepositDocument[],
  searchAddress: (query: string, type?: string|null, limit?: number|null) => Promise<void | AddressApiResult[]>,
  getCountries: () => Promise<void | Country[]>,
  manageableQualities?: ManageableQuality[]
}

const RenderAgentForm: FC<AgentFormProps> = ({
  handleChange,
  intervenants,
  foDisplay,
  boDisplay,
  checkIsRecipientNeeded,
  handleSelectIntervenant,
  user,
  idCurrentUser,
  depositAgent,
  fieldStatus,
  addAgentDocument,
  deleteAgentDocument,
  handleResetAgent,
  handleSelfAgent,
  intl,
  handleAgentForm,
  agentDocuments,
  searchAddress,
  getCountries,
  manageableQualities
}) => {
  const [currentAgent, setCurrentAgent] = useState<DepositIntervenant>(depositAgent)

  useEffect(() => {
    setCurrentAgent(depositAgent)
  }, [depositAgent])

  /**
   * Enregistrement des modifications du depositeur
   * @param event
   */
  const handleChanges = (event: EventType) => {
    const { name, value } = event.target

    const depositorPatches = {
      ...currentAgent,
      [name]: value
    }
    setCurrentAgent(depositorPatches)
    if (foDisplay) {
      handleChange && handleChange(event)
    }
  }

  return (
    <IntlProvider locale='fr' messages={Message}>
      <div className='form-header'>
        <div className='d-flex'>
          <h2 className={`mb-0 ${boDisplay && !foDisplay ? 'h4' : ''}`}>
            <FormattedMessage id='intervenant_onglet_mandataire' />
          </h2>

          <div className='col options px-0 my-auto'>
            {isFilled(intervenants) && (
              <SelectButton
                className='btn btn-link text-primary'
                options={intervenants}
                onSelect={handleSelectIntervenant}
              >
                <FormattedMessage id='intervenant_copier_donnees' />
              </SelectButton>
            )}
            {
              foDisplay &&
                <>
                  <div className='separator' />

                  {user &&
                idCurrentUser !== currentAgent.idUser &&
                (
                  <button className='btn btn-link text-primary' onClick={handleSelfAgent}>
                    <FormattedMessage id='intervenant_auto_mandataire' />
                  </button>
                )}
                  {idCurrentUser === currentAgent.idUser && (
                    <button className='btn btn-link text-primary' onClick={handleResetAgent}>
                      <FormattedMessage id='intervenant_reinitialiser' />
                    </button>
                  )}
                </>
            }
          </div>
        </div>
        {foDisplay &&
          <SubtitleIntervenant idText='intervenant_onglet_mandataire_subtitle' />}
      </div>

      <div className='header-field'>
        <FormattedMessage id='field_personne_type_label' />
      </div>

      <PersonnePhysiqueFields
        intervenant={currentAgent}
        fieldStatus={fieldStatus}
        onChange={handleChanges}
      />

      <PersonneContactFields
        intervenant={currentAgent}
        fieldStatus={fieldStatus}
        onChange={handleChanges}
      />

      <div className='row'>
        <div className='col-12 col-md-4'>
          <TextField
            type='companyName'
            inputId='companyName'
            label={<FormattedMessage id='field_company_label' />}
            value={currentAgent.companyName || ''}
            onChange={handleChanges}
            fieldStatus={fieldStatus}
            maxLength='120'
          />
        </div>

        <div className='col-12 col-md-4'>
          <ManageableQualitySelectField
            inputId='manageableQuality'
            fieldStatus={fieldStatus}
            value={currentAgent.manageableQuality}
            onChange={handleChanges}
            label={<FormattedMessage id='field_manageable_qualities_label' />}
            placeholder={intl.formatMessage({ id: 'placeholder_select' })}
            required
            manageableQualities={manageableQualities}
          />
        </div>
        <div className='col-12 col-md-4'>
          <FileBrowserField
            inputId='document'
            label={<FormattedMessage id='field_power_documents_label' />}
            className='font-weight-bold'
            value={agentDocuments}
            onChange={(event) => addAgentDocument && addAgentDocument(event, currentAgent)}
            onDelete={() => deleteAgentDocument && deleteAgentDocument(currentAgent)}
            fieldStatus={fieldStatus}
            maxNumberFile={1}
            acceptApplication='application/pdf'
            butonLabel={
              <div className='form-control placeholder'>
                <FormattedMessage id='placeholder_select' />
              </div>
            }
          />
          {foDisplay &&
            <a href='/aide/exemple_pouvoir_en_copropriete.doc'><FormattedMessage id='intervenant_mandataire_document_info' /></a>}
        </div>
        {
          (currentAgent.manageableQuality === CPI) &&
            <div className='col-12 col-md-4'>
              <TextField
                inputId='inscriptionNumber'
                label={<FormattedMessage id='field_inscription_number' />}
                fieldStatus={fieldStatus}
                value={currentAgent.inscriptionNumber}
                onChange={handleChanges}
                required
              />
            </div>
        }

        {
          checkIsRecipientNeeded &&
            <div className='col-12'>
              <CheckboxField
                inputId='isRecipient'
                label={<FormattedMessage id='field_agent_is_recipient' />}
                checked={currentAgent.isRecipient}
                onChange={handleChanges}
                className='mb-0'
              />
              {
                currentAgent.isRecipient &&
                  <span className='text-warning'>
                    <FormattedMessage id='intervenant_warning_agent_is_recipient' />
                  </span>
              }
            </div>
        }

      </div>

      <AddressFields
        onChange={handleChanges}
        address={currentAgent.address}
        fieldStatus={fieldStatus}
        searchAddress={searchAddress}
        getCountries={getCountries}
      />
      {
        boDisplay &&
          <div className='d-flex justify-content-around mt-2'>
            <Button className='primary' onClick={() => handleAgentForm && handleAgentForm(currentAgent)}>
              <FormattedMessage id='common_validate' />
            </Button>
          </div>
      }
    </IntlProvider>
  )
}

export default RenderAgentForm
